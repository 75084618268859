import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as Sentry from '@sentry/browser'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

import(
  /* webpackChunkName: "bootstrap" */ './bootstrap'
).then(({ bootstrap }) => bootstrap())

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
